<template>
  <v-container class="pa-0 ma-0 green-corner green-hill" fill-height fluid>
    <div id="triangle"></div>

    <v-main>
      <slot></slot>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'StaticLayout',
};
</script>

<style lang="scss" scoped>
#triangle {
  @include display(760px) {
    display: none;
  }
}
</style>
